<template>
    <div>
        <div v-if="authUserPermission['export-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4 mt--7">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h3 class="mb-0">{{ tt('export_data') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="height: 575px">
                    <div class="row">
                        <div class="col-6">
                            <base-input :label="tt('data')" :name="tt('table')">
                                <el-select class="select-danger" v-model="inputExportReport.table" :placeholder="tt('choose_data')">
                                    <el-option class="select-danger" :value="der" :label="der" :key="der"  v-for="der in dataExportReport"></el-option>
                                </el-select>
                            </base-input>

                            <span class="row mt--3" v-if="inputExportReport.table == 'MCR SHP'">
                                <span class="col-6">
                                    <label class="form-control-label">{{ tt('material_number') }}</label>
                                    <base-input :name="tt('material_number')" :placeholder="tt('material_number')" v-model="paramExport.material_number"></base-input>    
                                </span>
                                <span class="col-6">
                                    <label class="form-control-label">{{ tt('equipment_code') }}</label>
                                    <base-input :name="tt('equipment_code')" :placeholder="tt('equipment_code')" v-model="paramExport.equipment_code"></base-input>    
                                </span>
                            </span>

                            <base-button class="col-12" size="large" type="primary" disabled v-if="inputExportReport.table == ''">{{ tt('export') }}</base-button>
                            <base-button class="col-12" size="large" type="primary" @click="exportMcrShp" v-else-if="inputExportReport.table == 'MCR SHP'">Export as Excel</base-button>
                            <a :href="apiUrl+'export/export-table?table='+inputExportReport.table+'&token='+token" class="col-12 btn btn-primary" v-else>Export as Excel</a>
                        </div>
                        <div class="col-6 text-center">
                            <img src="/assets/smartcat/export.png" alt="Data Is Empty" width="500px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import exportReport from '@/services/report/export.service';
    import baseApiUrl from '@/configs/config';
    
    export default {        
        data() {
            return {         
                exportReport: {} ,
                inputExportReport: {
                    table: ''
                },
                dataExportReport: {},
                token: localStorage.token,
                apiUrl: baseApiUrl.apiUrl,
                paramExport: {
                    material_number: '',
                    equipment_code: '',
                }
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;
                Api(context, exportReport.index()).onSuccess(function(response) {
                    context.dataExportReport = response.data.data
                }).call();
            },
            exportMcrShp() {
                let context = this;
                if (!context.paramExport.material_number && !context.paramExport.equipment_code) {
                    context.$notify({
                        message: this.tt("please_complete_data_excel_mcr_shp"),
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return false;
                } else {
                    const linkExcel = context.apiUrl+'export/export-mcr-shp?material_number='+context.paramExport.material_number+'&equipment_code='+context.paramExport.equipment_code+'&token='+context.token
                    
                    window.location.href = linkExcel
                    
                    context.paramExport.material_number = ''
                    context.paramExport.equipment_code  = ''
                }
            }
            // exportTable() {
            //     let context = this;
            //     Api(context, exportReport.export_table()).onSuccess(function(response) {
            //         context.dataExportReport = response.data.data
            //         console.log(response.data.data)
            //     }).call();
            // },
        }   
    };
</script>
<style></style>
