import Service from '@/helpers/service'

export default {

    index() {
        return Service().get('export');
    }, 
    export_table(params) {
        return Service().get('export/export-table/', {params});
    },    
}